import React, { PureComponent } from 'react';
import Preloader from '../Preloader'
import LoaderForm from '../LoaderForm'
import Questions from '../Questions'

class Main extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      let $ = window.$;
        $('[data-loader="circle-side"]').fadeOut(); // will first fade out the loading animation
        $('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
        $('body').delay(350).css({
          'overflow': 'visible'
        });
    }, 1000)
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
        <div>
          <Preloader style={{display: this.state.mounted ? "none" : "auto" }}></Preloader>
          <LoaderForm></LoaderForm>
          <Questions></Questions>


          <div className="cd-overlay-nav">
            <span></span>
          </div>

          <div className="cd-overlay-content">
            <span></span>
          </div>

          {/* <a href="#0" className="cd-nav-trigger">Menu<span className="cd-icon"></span></a> */}
          {/* <TermsAndConditions></TermsAndConditions> */}
        </div>
    );
  }
}

Main.propTypes = {
  // bla: PropTypes.string,
};

Main.defaultProps = {
  // bla: 'test',
};

export default Main;
