import React, { PureComponent } from 'react';
//import { Test } from './Date.styles';

class Date extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  onChange = () => {
    this.props.answer.clicked = true;
    
    let name = this.props.answer.parent + "";
    this.props.answer.value = window.$("[name='" + name + "']").val();
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <div className="form-group">
        <input type="date" name={this.props.answer.parent + ""} className="form-control required" placeholder={this.props.answer.question} onChange={this.onChange} value={this.props.answer.value} />
      </div>
    );
  }
}

Date.propTypes = {
  // bla: PropTypes.string,
};

Date.defaultProps = {
  // bla: 'test',
};

export default Date;
