import React, { PureComponent } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
//import { Test } from './Answer.styles';

class Answer extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  onAnswerClick = () => {
    let node = ReactDOM.findDOMNode(this);
    window.getVals(node, 'question_1');
    
    this.props.answer.clicked = true;
    this.props.action(this.props.answer);
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <div className="form-group">
        <label className="container_radio version_2">{this.props.answer.question}
          <input type="radio" name={this.props.answer.parent + ""}  value={this.props.answer.id} className="required" onClick={this.onAnswerClick} defaultChecked={this.props.answer.clicked} />
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }
}

Answer.propTypes = {
  // bla: PropTypes.string,
};

Answer.defaultProps = {
  // bla: 'test',
};

export default Answer;
