class LawyerCommissionCalculator {    
  // Формула в B1:
  // Цена на иск = х
  // Адв. хонорар = y
  // Ако: x<=1000 лв | y = 300 лв хонорар
  // Ако: 1000лв<x<=5000лв | у = 300 + 7(х-1000)/100
  // Ако: 5000лв<x<=10 000лв | y = 580 + 5(х-5000)/100
  // Ако: 10 000лв<x<=100 000лв | y = 830 + 3(х-10 000)/100
  // Ако: 100 000лв<х<=1 000 000лв | y = 3530 + 2(х-100 000)/100
  // Ако: 1 000 000лв<х<=10 000 000лв | y = 21 530 + (х-1 000 000)/100
  // Ако: x>10 000 000лв | y = 111 530 + (х-10 000 000)/200
  // Лични
  static b1 = (amount) => {
    let val = LawyerCommissionCalculator.b2(amount);
    val = val > 600 ? val : 600;

    return val;
  }

  // Формула в B2:
  // Цена на иск = х
  // Адв. хонорар = y
  // Ако: x<=1000 лв | y = 300 лв хонорар
  // Ако: 1000лв<x<=5000лв | у = 300 + 7(х-1000)/100
  // Ако: 5000лв<x<=10 000лв | y = 580 + 5(х-5000)/100
  // Ако: 10 000лв<x<=100 000лв | y = 830 + 3(х-10 000)/100
  // Ако: 100 000лв<х<=1 000 000лв | y = 3530 + 2(х-100 000)/100
  // Ако: 1 000 000лв<х<=10 000 000лв | y = 21 530 + (х-1 000 000)/100
  // Ако: x>10 000 000лв | y = 111 530 + (х-10 000 000)/200
  // Лични
  static b2 = (amount) => {
    if(amount <= 1000) return 300;
    else if (amount > 1000 && amount <= 5000) return 300 + 7 * (amount - 1000) / 100;
    else if (amount > 5000 && amount <= 10000) return 580 + 5 * (amount - 5000) / 100;
    else if (amount > 10000 && amount <= 100000) return 830 + 3 * (amount - 10000) / 100;
    else if (amount > 100000 && amount <= 1000000) return 3530 + 2 * (amount - 100000) / 100;
    else if (amount > 1000000 && amount <= 10000000) return 21530 + 2 * (amount - 1000000) / 100;
    else if (amount > 10000000) return 111530 + 2 * (amount - 10000000) / 200;
  }

  // Формула в B3:
  // Цена на иск = х
  // Адв. хонорар = y
  // Ако: 0,5x<=1000 лв | y = 300 лв хонорар
  // Ако: 1000лв<0,5x<=5000лв | у = 300 + 7(0,5х-1000)/100
  // Ако: 5000лв<0,5x<=10 000лв | y = 580 + 5(0,5х-5000)/100
  // Ако: 10 000лв<0,5x<=100 000лв | y = 830 + 3(0,5х-10 000)/100
  // Ако: 100 000лв<0,5х<=1 000 000лв | y = 3530 + 2(0,5х-100 000)/100
  // Ако: 1 000 000лв<0,5х<=10 000 000лв | y = 21 530 + (0,5х-1 000 000)/100
  // Ако: 0,5x>10 000 000лв | y = 111 530 + (0,5х-10 000 000)/200
  static b3 = (amount) => {
    amount = amount / 2;
    if(amount <= 1000) return 300;
    else if (amount > 1000 && amount <= 5000) return 300 + 7 * (amount - 1000) / 100;
    else if (amount > 5000 && amount <= 10000) return 580 + 5 * (amount - 5000) / 100;
    else if (amount > 10000 && amount <= 100000) return 830 + 3 * (amount - 10000) / 100;
    else if (amount > 100000 && amount <= 1000000) return 3530 + 2 * (amount - 100000) / 100;
    else if (amount > 1000000 && amount <= 10000000) return 21530 + 2 * (amount - 1000000) / 100;
    else if (amount > 10000000) return 111530 + 2 * (amount - 10000000) / 200;
  }

  // Формула в B6:
  // Материален интерес (клетка B7 в таблицата) = х
  // Адв. хонорар = y
  // Ако: x=0лв | (тогава) y = 400 лв хонорар
  // Ако: 0лв<x<=1000лв | у = 470 лв
  // Ако: 1000лв<x<=10 000лв | y = 550 + (х-1000)/100
  // Ако: 10 000лв<x<=50 000лв | y = 650 + (х-10 000)/200
  // Ако: 50 000лв<х<=100 000лв | y = 900 + (х-50 000)/500
  // Ако: x>100 000лв | y = 1100 + (х-100 000)/1000
  // Бизнес и потребителски
  static b6 = (amount) => {
    if(amount === 0) return 400;
    if(amount > 0 && amount <= 1000) return 470;
    if(amount > 1000 && amount <= 10000) return 550 + (amount - 1000) / 100;
    if(amount > 10000 && amount <= 50000) return 650 + (amount - 10000) / 200;
    if(amount > 50000 && amount <= 100000) return 900 + (amount - 50000) / 500;
    if(amount > 100000) return 1100 + (amount - 100000) / 1000;
  }
  
  // Цена на иск = х
  // Адв. хонорар = y
  // Ако: x<=5400 лв | y = 600 лв хонорар
  // Ако: 5400лв<x<=10 000лв | y = 580 + 5(х-5000)/100
  // Ако: 10 000лв<x<=100 000лв | y = 830 + 3(х-10 000)/100
  // Ако: 100 000лв<x<=1 000 000лв | y = 3530 + 2(х-100 000)/100
  // Ако: 1 000 000лв<x<=10 000 000лв | y = 21 530 + (х-1 000 000)/100
  // Ако: x>10 000 000лв | y = 111 530 + (х-10 000 000)/200
  static b10 = (amount) => {
    if(amount <= 5400) return 600;
    if(amount > 5400 && amount <= 10000) return 580 + 5 * (amount - 5000) / 100;
    else if (amount > 10000 && amount <= 100000) return 830 + 3 * (amount - 10000) / 100;
    else if (amount > 100000 && amount <= 1000000) return 3530 + 2 * (amount - 100000) / 100;
    else if (amount > 1000000 && amount <= 10000000) return 21530 + 2 * (amount - 1000000) / 100;
    else if (amount > 10000000) return 111530 + 2 * (amount - 10000000) / 200;
  }

  static addFlat = (amount) => {
    return amount * 1.2;
  }
}

export default LawyerCommissionCalculator;
