export let ArbitrageData = {
    arbitrage: [
        {
            name: "Арбитражен съд към БТПП",
        },
        {
            name: "Арбитражен съд към Криби",
        },
        {
            name: "Арбитражният съд при Европейска юридическа палата",
        },
        {
            name: "Софийският арбитражен съд към Международна асоциация за правосъдие и арбитраж",
        },
        {
            name: "Арбитражен съд към Съюза на Арбитрите",
        },
        {
            name: "Арбитражен съд при СППМ",
        },
        {
            name: "Софийски арбитражен съд към За развитие на правото в България",
        },
        {
            name: "Други",
        },
    ]
}