import React, { PureComponent } from 'react';
import { Data } from '../Questions/data';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LawyerCommissionCalculator from '../laywer-comissoin-calculator'
import ReactDOM from 'react-dom';
//import { Test } from './AnswerPage.styles';

class AnswerPage extends PureComponent { 
  constructor(props) {
    super(props);

    let isDateRelevant = (dateString) => {
      if(!dateString)
      {
        return;
      }

      let date = new Date(Date.parse(dateString));
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      let afterDate = new Date(year + 3, month, day);
      let now = new Date();

      console.log(now);
      console.log(afterDate);
      console.log(afterDate < now)
  
      return afterDate < now;
    }

    let answerKey = props.location.state.key;
    console.log(answerKey.map(ak => ak.id));

    let clickedQuestions = props.location.state.clickedQuestions;
    clickedQuestions.pop();

    let match = (key, selectedAnswers) => {
      let selectedKey = selectedAnswers
        .map(a => a.id);
      let keyArray = key.split(/[,.]+/).map(k => k.trim()).filter(k => k != "");
      let matchHits = 0;

      let dateAnswers = [42, 80, 99]
      let dateAnswer = selectedAnswers.find(a => dateAnswers.some(da => da === a.id) && a.value)
  
      for (const keyP of keyArray) {
        let keyParts = keyP.split(/[(|)]/).filter(w => w != "").map(w => parseInt(w));
        let part = selectedKey.find(sk => keyParts.some(kp => kp == sk));

        if(!part)
        {
          return matchHits;
        }

        if(dateAnswer && dateAnswers.some(da => da === part))
        {
          let isRelevant = isDateRelevant(dateAnswer.value);
          console.log(isRelevant);
          if(isRelevant)
          {
            matchHits = matchHits + 3;
          } else {
            matchHits = matchHits - 1;
          }
        }
        
        matchHits++;
      }

      return matchHits;
    }

    let sorted = answerKey !== undefined ? Data.SmartAnswers.sort((a, b) => match(b.key, answerKey) - match(a.key, answerKey)) : [ Data.SmartAnswers[0] ];
    let answer = sorted[0];
    this.reworkAnswer(sorted[0], answerKey);

    let isArbitrage = answerKey.some(a => a.id === 64);
    let emailAnswer = answerKey.find(a => a.id === 147);
    let email = emailAnswer && emailAnswer.value;

    this.state = {
      hasError: false,
      answer: answer,
      answerKey: answerKey,
      clickedQuestions: clickedQuestions,
      data: props.location.state.data,
      isArbitrage: isArbitrage,
      email: email
    };
  }


  reworkAnswer = (rawAnswer, key) => {
    console.log(rawAnswer);
    let location = this.getCourtLocation(key);

    if(rawAnswer.mediationText && typeof(rawAnswer.mediationText) === "string")
    {
      rawAnswer.mediationText = this.reworkAnswerText(rawAnswer.mediationText);
    }

    if(rawAnswer.courtText && typeof(rawAnswer.courtText) === "string")
    {
      console.log(rawAnswer);
      rawAnswer.courtText = rawAnswer.courtText.replace("{courtLocation}", location);
      rawAnswer.courtText = this.reworkAnswerText(rawAnswer.courtText);
    }

    if(rawAnswer.courtCost && typeof(rawAnswer.courtCost) === "string")
    {
      let alimony = this.getAlimony(key);
      let property = this.getProperty(key);
      let lawyer = this.getLawyer(key);
      let total = 25 + alimony + property + lawyer;

      console.log(total + " = 25 + " + alimony + " + " + property + " + " + lawyer);

      let businessProperty = this.getBusinessProperty(key);
      let businessLawyer = this.getBusinessLawyer(key);
      let businessTotal = businessProperty + businessLawyer;

      rawAnswer.courtCost = rawAnswer.courtCost.replace("{alimony}", alimony.toFixed(2));
      rawAnswer.courtCost = rawAnswer.courtCost.replace("{property}", property.toFixed(2));
      rawAnswer.courtCost = rawAnswer.courtCost.replace("{lawyer}", lawyer.toFixed(2));
      rawAnswer.courtCost = rawAnswer.courtCost.replace("{total}", total.toFixed(2));
      rawAnswer.courtCost = rawAnswer.courtCost.replace("{businessProperty}", businessProperty.toFixed(2));
      rawAnswer.courtCost = rawAnswer.courtCost.replace("{businessLawyer}", businessLawyer.toFixed(2));
      rawAnswer.courtCost = rawAnswer.courtCost.replace("{businessTotal}", businessTotal.toFixed(2));
      rawAnswer.courtCost = this.reworkAnswerText(rawAnswer.courtCost);
    }

    if(rawAnswer.arbitrageText && typeof(rawAnswer.arbitrageText) === "string")
    {
      console.log(rawAnswer.arbitrageText)
      rawAnswer.arbitrageText = rawAnswer.arbitrageText.replace("{courtLocation}", location);
      rawAnswer.arbitrageText = this.reworkAnswerText(rawAnswer.arbitrageText);
    }
    return rawAnswer;
  }

  getAlimony = (key) => {
    let ids = [16, 136];
    let value = 0;
    for (const id of ids) {
      let answer = key.find(a => a.id === id);
      let answerValue = answer ? parseInt(answer.value) : 0;
      value = value + (answerValue ? answerValue : 0);

      console.log("alimony value: " + answerValue);
    }

    console.log("alimony total: " + value);

    let alimony = LawyerCommissionCalculator.b2(value);
    console.log("alimony tax: " + alimony);
    return alimony;
  }

  getProperty = (key) => {
    let ids = [22, 23, 24];
    let value = 0;
    for (const id of ids) {
      let answer = key.find(a => a.id === id);
      let answerValue = answer ? parseInt(answer.value) : 0;
      value = value + (answerValue ? answerValue : 0);
      console.log("property value: " + answerValue);
    }

    console.log("property total: " + value);
    let hasMarriage = key.some(a => a.id === 5);
    
    let coef = hasMarriage ? 2 : 4;
    console.log("property coef: " + coef)
    
    value = (value) * (coef / 100);
    console.log("property tax: " + value);
    return value;
  }

  getLawyer = (key) => {
    let ids = [14, 16, 136, 22, 23, 24];
    let value = 0;
    for (const id of ids) {
      let answer = key.find(a => a.id === id);
      let answerValue = answer ? parseInt(answer.value) : 0;
      value = value + (answerValue ? answerValue : 0);
      
      console.log("lawyer value: " + answerValue);
    }

    console.log("lawyer base: " + value);
    value = value ? LawyerCommissionCalculator.b1(value) : 0;

    console.log("lawyer tax: " + value);
    return value;
  }

  getBusinessProperty = (key) => {
    let ids = [44, 55, 82, 101, 139, 142, 149, 151, 155, 158];
    let value = 0;
    for (const id of ids) {
      let answer = key.find(a => a.id === id);
      let answerValue = answer ? parseInt(answer.value) : 0;
      value = value + (answerValue ? answerValue : 0);
    }

    let interestAnswer = key.find(a => a.id === 153);
    let interestRate = interestAnswer ? interestAnswer.value : 0;

    let dueToAnswer = key.find(a => a.id === 42);
    let dueDate = dueToAnswer ? new Date(Date.parse(dueToAnswer.value)) : null;
    let now = new Date();
    
    const diffTime = dueDate ? Math.abs(now - dueDate) : 0;
    const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365); 

    let koef = 4;

    value = (value + value * interestRate * diffYears) * (koef / 100);

    value = value < 50 ? 50 : value;
    return value;
  }

  getBusinessLawyer = (key) => {
    let ids = [44, 55, 82, 101, 139, 142, 149, 151, 155, 158];
    let value = 0;
    for (const id of ids) {
      let answer = key.find(a => a.id === id);
      let answerValue = answer ? parseInt(answer.value) : 0;
      value = value + (answerValue ? answerValue : 0);
    }


    console.log("businessLawyer Value: " + value)

    let interestAnswer = key.find(a => a.id === 153);
    let interestRate = interestAnswer ? interestAnswer.value : 0;

    console.log("interestRate: " + interestRate)


    let dueToAnswer = key.find(a => a.id === 42);
    let dueDate = dueToAnswer ? new Date(Date.parse(dueToAnswer.value)) : null;
    let now = new Date();
    
    const diffTime = dueDate ? Math.abs(now - dueDate) : 0;
    const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365); 

    console.log("diffYears: " + diffYears)

    value = value + value * interestRate * diffYears
    console.log("Total Business Lawyer Base: " + value);
    
    let bigBaseAnswers = [50, 59, 92, 111];
    let hasBigBase = key.some(a => bigBaseAnswers.some(sm => sm == a.id));
    value = hasBigBase ? LawyerCommissionCalculator.b10(value) : LawyerCommissionCalculator.b2(value) ;
    console.log("BusinessLawyer Tax: " + value);

    return value;
  }

  getCourtLocation = (key) => {
    let courtIds = [116, 132, 67, 69];

    for (const answerId of courtIds) {
      let answer = key.find(a => a.id == answerId)
      if(answer && answer.value)
      {
        console.log("Location Answer: " + answer.value);
        let location = answer.value.split("/")

        console.log("Location: " + location[1]);
        return location[1] || location[0];
      }
    }
  }

  emailClick = () => {
    let $ = window.$;
    let message = this.getEmailContent();
    let element = document.createElement("div");
    ReactDOM.render(message, element);

    let messageInnerHTML = element.innerHTML;
    console.log(messageInnerHTML);

    $.post({
      url: "http://questions.legalsp.eu/api/email/send.php",
      //url: "http://localhost/api/email/send.php",
      data: JSON.stringify({
        email: this.state.email,
        subject: "Legal Balancer Results",
        message: messageInnerHTML,
      }),
      success: () => alert("Success! Message Sent!"),
      error: (e) => {
        console.log(e);
        console.log(e.statusCode());
        
        let text = "Грешка при изпращането! Пробвайте да принтирате!"
        if (e.status === 400){
          text = "Изпратено успешно! Проверете пощата си!"
        }

        $('.modal-body').text(text);
        $('.popup-button').click();
      },
      context: document.body,
    })
  }

  getEmailContent = () => {
    let cells = this.state.clickedQuestions === undefined ? "" : this.state.clickedQuestions
      .map(cq =>  <td key={cq.id} style={{"padding-right": "20px","padding-top": "10px", "vertical-align": "top"}}> 
        <div style={{"font-weight": "bold"}}>{cq.question}</div>
                  {
                    this.state.data.filter(q => q.parent === cq.id && q.clicked)
                      .map(q => {
                                  switch(q.id)
                                  {
                                    case 66:
                                    case 69:
                                    case 116:
                                    case 132:
                                      let value = q.value;

                                      if(value !== undefined)
                                      {
                                        console.log();
                                        var split = value.split("/");
                                        value = split[0];
                                      }
                                      return <span key={q.id}> {q.question} {value !== undefined ? <strong style={{"font-weight": "bold"}}> {" " + value} </strong> : ""}</span>
                                    default:
                                      return <span key={q.id}> {q.question} {q.value !== undefined ? <strong style={{"font-weight": "bold"}}> {" " + q.value} </strong> : ""}</span>
                                    }
                                  }
                          )
                  }
                      
                </td>
        )

    let rows = [];
    let empty = <td></td>;
    for(let i = 0; i < cells.length; i += 4) {
      rows.push(<tr>{cells[i+0] || empty}{cells[i+1] || empty}{cells[i+2] || empty}{cells[i+3] || empty}</tr>)
    }

    let mediation = this.state.answer.mediationText != undefined ? 
    <div>
      <h4 style={{"margin-top": "20px"}}>Медиация</h4>
      <div>{this.state.answer.mediationText}</div>
      {this.state.answer.mediationTime != undefined ? <h5 style={{"margin-top": "20px"}}>Време</h5> : ""}
      {this.state.answer.mediationTime != undefined ? <div>{this.state.answer.mediationTime}</div> : ""}
      {this.state.answer.mediationCost != undefined ? <h5 style={{"margin-top": "20px"}}>Цена</h5> : ""}
      {this.state.answer.mediationTime != undefined ? <div>{this.state.answer.mediationCost}</div> : ""}
    </div>
    : ""

    let court = (this.state.answer.courtText != undefined && !this.state.isArbitrage) ?
    <div>
      <h4 style={{"margin-top": "20px"}}>Съд</h4>
      <div>{this.state.answer.courtText}</div>
      {this.state.answer.courtTime != undefined ? <h5 style={{"margin-top": "20px"}}>Време</h5> : ""}
      {this.state.answer.courtTime != undefined ? <div>{this.state.answer.courtTime}</div> : ""}
      {this.state.answer.courtCost != undefined ? <h5 style={{"margin-top": "20px"}}>Цена</h5> : ""}
      {this.state.answer.courtCost != undefined ? <div>{this.state.answer.courtCost}</div> : ""}
    </div>
    : ""

    let arbitrage = (this.state.answer.arbitrageText != undefined && this.state.isArbitrage) ?
    <div>
      <h4 style={{"margin-top": "20px"}}>Арбитраж</h4>
      <div>{this.state.answer.arbitrageText}</div>
      {this.state.answer.arbitrageTime != undefined ? <h5 style={{"margin-top": "20px"}}>Време</h5> : ""}
      {this.state.answer.arbitrageTime != undefined ? <div >{this.state.answer.arbitrageTime}</div> : ""}
      {this.state.answer.arbitrageCost != undefined ? <h5 style={{"margin-top": "20px"}}>Време</h5> : ""}
      {this.state.answer.arbitrageCost != undefined ? <div>{this.state.answer.arbitrageCost}</div> : ""}
    </div>
    : ""

    let mandatory = 
    <div>
      <p className="text-justify">Онлайн интерактивен въпросник за независима и безпристрастна оценка на възможностите за решаване на конкретни правни казуси и вариантите за извънсъдебното им решаване е разработен по проект „Правен балансьор - онлайн достъп до правосъдие“ по административен договор за предоставяне на безвъзмездна финансова помощ с рег. № BG05SFOP001-3.003-0079 от 18.02.2019 г. с бенефициент Сдружение "Център за законодателни оценки и законодателни инициативи", финансиран по Оперативна програма „Добро управление“, съфинансирана от Европейския съюз чрез Европейския социален фонд. Проектът е на стойност 83 215,30 лв., от които 70 733,00 лв. европейско финансиране и 12 482,30 лв. национално финансиране.</p>
    </div>

    return (
      <div>
        <div style={{"border": "1px solid blue", "border-radius": "10% / 50%", "padding": "50px"}}>
          <table>
            {rows}
          </table>
        </div>
        {mediation}
        {court}
        {arbitrage}
        {mandatory}
      </div>
    )
  }

  reworkAnswerText = (rawText) => {
    if(typeof(rawText) !== "string")
    {
      return rawText;
    }

    let paragraphs = rawText.split("<br />");
    let elements = paragraphs.map(p => <p key={Math.floor(Math.random() * 10000)} dangerouslySetInnerHTML={{__html: p}}></p>);

    var text = <div>
      {elements}
    </div>
    return text;
  }

  generatePdf = () => {
    let doc = new jsPDF('l', 'px', 'a1');

    let element = document.querySelector("body");

    html2canvas(element)
      .then(canvas => {
        doc.addImage(canvas, 'JPEG', -70, 0, canvas.width, canvas.height);
        doc.save('legal-balancer.pdf');
      });
  }

  getAnswersGrid = () => {
    return <div className="page-answers container my-5 border border-primary py-3 text-left" style={{borderRadius: "10% / 50%"}}>
            <div className="row px-5">
              {this.state.clickedQuestions === undefined ? "" : this.state.clickedQuestions
                  .map(cq =>  <div key={cq.id}  className="col-md-3 py-1"> <div className="font-weight-bold">{cq.question}</div>
                                {this.state.data.filter(q => q.parent === cq.id && q.clicked)
                                    .map(q => {
                                      switch(q.id)
                                      {
                                        case 66:
                                        case 69:
                                        case 116:
                                        case 132:
                                          let value = q.value;

                                          if(value !== undefined)
                                          {
                                            console.log();
                                            var split = value.split("/");
                                            value = split[0];
                                          }

                                          return <span key={q.id}> {q.question} {value !== undefined ? <strong className="font-weight-bold"> {" " + value} </strong> : ""}</span>
                                        default:
                                        return <span key={q.id}> {q.question} {q.value !== undefined ? <strong className="font-weight-bold"> {" " + q.value} </strong> : ""}</span>
                                        }
                                      }
                                        )
                                      }
                                    
                              </div>
                      )}
            </div>
          </div>
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    let answers = this.getAnswersGrid();

    return (
      <div className="answer-page text-justify p-5">
        <div>
          <div className="">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col col-lg-2">
                  <img src="img/sponsor1.png" height="100px"/>
                </div>
                <div className="col-md text-center" >
                  <img src="img/sponsor2.png" height="100px"/>
                </div>
                <div className="col col-lg-2">
                  <img src="img/sponsor3.png" height="100px"/>
                </div>
              </div>
            </div>
            <div>
              <h4 className="text-center mt-3">Възможни решения на Вашия казус</h4>
            </div>
            {answers}
            <div className="text-center">
              <Link to="/"><button type="button" className="forward">Редактирай данните</button></Link>
            </div>
            <div className="content-page mt-3">
              <div style={{display: this.state.answer.mediationText != undefined ? "auto" : "none"}} className="col-sm">
                <h4 className="text-center mb-4">Медиация</h4>
                <div>{this.state.answer.mediationText}</div>
                <h5 style={{display: this.state.answer.mediationTime != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Време</h5>
                <div>{this.state.answer.mediationTime}</div>
                <h5 style={{display: this.state.answer.mediationCost != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Цена</h5>
                <div>{this.state.answer.mediationCost}</div>
              </div>
              <div style={{display: (this.state.answer.courtText != undefined && !this.state.isArbitrage) ? "auto" : "none"}} className="col-sm">
                <h4 className="text-center mb-4">Съд</h4>
                <div>{this.state.answer.courtText}</div>
                <h5 style={{display: this.state.answer.courtTime != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Време</h5>
                <div>{this.state.answer.courtTime}</div>
                <h5 style={{display: this.state.answer.courtCost != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Цена</h5>
                <div>{this.state.answer.courtCost}</div>
              </div>
              <div style={{display: (this.state.answer.arbitrageText != undefined && this.state.isArbitrage) ? "auto" : "none"}} className="col-sm">
                <h4 className="text-center mb-4">Арбитраж</h4>
                <div>{this.state.answer.arbitrageText}</div>
                <h5 style={{display: this.state.answer.arbitrageTime != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Време</h5>
                <div >{this.state.answer.arbitrageTime}</div>
                <h5 style={{display: this.state.answer.arbitrageCost != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Цена</h5>
                <div>{this.state.answer.arbitrageCost}</div>
              </div>
            </div>
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col text-center" style={{marginBottom: "30px"}}>
                    <img src="img/sponsor7.png" style={{maxWidth: "22%"}} alt="#" />
                    <img src="img/sponsor3.png" style={{maxWidth: "28%"}} alt="#" />
                  </div>
                </div>
              </div>
              <p className="text-justify">Онлайн интерактивен въпросник за независима и безпристрастна оценка на възможностите за решаване на конкретни правни казуси и вариантите за извънсъдебното им решаване е разработен по проект „Правен балансьор - онлайн достъп до правосъдие“ по административен договор за предоставяне на безвъзмездна финансова помощ с рег. № BG05SFOP001-3.003-0079 от 18.02.2019 г. с бенефициент Сдружение "Център за законодателни оценки и законодателни инициативи", финансиран по Оперативна програма „Добро управление“, съфинансирана от Европейския съюз чрез Европейския социален фонд. Проектът е на стойност 83 215,30 лв., от които 70 733,00 лв. европейско финансиране и 12 482,30 лв. национално финансиране.</p>
            </div>
            <div className="text-center mt-3">
              <button type="button" className="forward" onClick={() => window.print()}>Принтирай</button>
              {/* <button type="button" className="forward" onClick={() => this.generatePdf()}>Запази в .pdf</button> */}
              <button style={{display: (this.state.email != undefined) ? "auto" : "none"}} type="button" className="forward" onClick={() => this.emailClick() }>Изпрати на Email</button>
            </div>
          </div>
        </div>

        {/* {this.getEmailContent()} */}

        <button type="button" className="btn forward popup-button" data-toggle="modal" data-target="#exampleModal" style={{display: 'none' }}>
          Email status:
        </button>
        <div className="popup modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Изпращане на съобщение</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Съобщението изпратено успешно!
              </div>
              <div className="modal-footer">
                <button type="button" className="btn forward" data-dismiss="modal">Затвори</button>
                {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      
    );
  }
}

AnswerPage.propTypes = {
  // bla: PropTypes.string,
};

AnswerPage.defaultProps = {
  // bla: 'test',
};

export default AnswerPage;
