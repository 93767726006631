import React, { PureComponent } from 'react';
import { CourtData } from '../../data-courts'
import { ArbitrageData } from '../../data-arbitrage'
//import { Test } from './Dropdown.styles';

class Dropdown extends PureComponent { 
  constructor(props) {
    super(props);

    let options = this.buildOptions(true, false);

    this.state = {
      hasError: false,
      options: options,
      isArbitrage: props.answer.id === 67,
    };
  }

  onChange = () => {
    this.props.answer.clicked = true;
    
    let name = this.props.answer.parent + "";
    this.props.answer.value = window.$("[name='" + name + "']").val();
  }

  onKeyDown = () => {
    let filterString = window.$("#filter").val();
    this.buildOptions(filterString);
  }

  buildOptions = (filterString, mounted = true) => {
    let isArbitrage = this.props.answer.id === 67;

    let options = [];
    if(isArbitrage) {
      options = ArbitrageData.arbitrage
          .filter(c => filterString === true || !filterString || c.name.toLowerCase().includes(filterString.toLowerCase()))
          .map((c, i) => <option key={i} value={c.name}>{c.name}</option>)

    } else {
      let cities = [];
      let data = CourtData.courts

      let clicked = this.props.data
        .filter(q => q.clicked === true)

      let isPersonal = clicked.some(q => q.id == 2);
      let materialInterestIds = [44, 55, 82, 101, 139, 142, 149, 151, 155, 158];
      
      let materialInterestTotal = clicked
        .filter(cq => materialInterestIds.some(miId => miId === cq.id))
        .map(cq => parseInt(cq.value))
        .reduce((a,b) => a + b, 0);

      console.log("materialInterestTotal: " + materialInterestTotal)

      let underLimit = materialInterestTotal < 25000;

      if(isPersonal || underLimit)
      {
        data = data.filter(cd => cd.name.toLowerCase().includes("районен"));
      } else {
        data = data.filter(cd => !cd.name.toLowerCase().includes("районен"));
      }

      for (const courtData of data) {
        let settlements = courtData.area && courtData.area
          .split(/[,:;]+/)
          .filter(s => s.includes("гр."))
          .filter(c => filterString === true || !filterString || c.toLowerCase().includes(filterString.toLowerCase()))
          .map(s => { return {value: courtData.name.trim(), text: s.trim() } } );
        
        if(settlements && settlements.length)
        {
          cities = cities.concat(settlements)
        }
      }
  
      let filtered = [];
      for(let city of cities)
      {
        if(city && !filtered.some(f =>  f.text === city.text))
        {
          filtered.push(city);
        }
      }
      
      options = filtered.map((c, i) => <option key={i} value={c.text + "/" + c.value}>{c.text}</option>)
    }

    if(mounted)
    {
      this.setState({
        options: options,
      })
    } else {
      return options;
    }
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <div className="form-group">
        <div className="styled-select clearfix">
          <label>Филтър: </label>
          <input id="filter" className="form-control wide" type="text" onKeyDown={this.onKeyDown} onKeyUp={this.onKeyDown} onClick={this.onKeyDown}/>
          <select name={this.props.answer.parent + ""} className="form-control wide required mt-2" onChange={this.onChange} defaultValue={this.props.answer.value || "default"} >
            <option disabled value="default">Изберете {this.state.isArbitrage ? "aрбитражен съд" : "град"}</option>
            {this.state.options}
          </select>
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  // bla: PropTypes.string,
};

Dropdown.defaultProps = {
  // bla: 'test',
};

export default Dropdown;
