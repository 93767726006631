import React, { PureComponent } from 'react';
import { Data } from './data'
import Question from './Question'
import Panel from './Panel'
import TermsAndConditions from './../TermsAndConditions'
import history from '../history';
import { withRouter } from "react-router-dom";

class Questions extends PureComponent { 
  constructor(props) {
    super(props);

    let question = Data.Questions.filter(question => question.id === 1);
    let remainingQuestions = Data.Questions.filter(question => question.parent === 1 && question.isAnswer === false);
    

    this.state = {
      hasError: false,
      question: question[0],
      clickedQuestions: [question[0]],
      remainingQuestions: remainingQuestions,
      complete: false,
    };
  }

  componentDidMount = () => {
    let $ = window.$;
    window.setTimeout(() => {
      $("button.forward")[0].disabled = false;
    }, 500);
  }

  componentDidUpdate = () => {
    let $ = window.$;
    let max = this.state.clickedQuestions.length + this.state.remainingQuestions.length
    $("#progressbar").progressbar("option", "max", max);
    $("#progressbar").progressbar( "option", "value", this.state.clickedQuestions.length);
  }

  onAnswerClick = () => {
    let clickedQuestions = Data.Questions
      .filter(question => question.parent === this.state.question.id)
      .filter(question => question.isAnswer === true)
      .filter(question => question.clicked)
    
    if((!window.$("form#wrapped").validate().form() && !clickedQuestions.some(cq => cq.type === "checkbox")) || clickedQuestions.length === 0)
    {
      return;
    }

    let question = clickedQuestions[0];
    let remaining = [];
    let answers = [];
    let otherClicked = [];
    for(let clickedQuestion of clickedQuestions)
    {
      let remainingQuestions = Data.Questions.filter(question => question.isAnswer === false && question.parent === clickedQuestion.id);
      answers = Data.Questions.filter(q => q.parent === clickedQuestion.id && q.isAnswer === true);

      remaining = remaining.concat(remainingQuestions);

      if(clickedQuestion !== question && answers.length !== 0)
      {
        otherClicked.push(clickedQuestion);
      }
    }

    while(answers.length === 0 && (remaining.length > 0 || this.state.remainingQuestions.length > 0))
    {
      if(remaining.length > 0) {
        question = remaining.shift();
      }
      else {
        question = this.state.remainingQuestions.shift();
      }

      answers = Data.Questions.filter(q => q.parent === question.id && q.isAnswer === true);
      let newRemainingQuestions = Data.Questions.filter(q => q.isAnswer === false && q.parent === question.id);
      remaining = newRemainingQuestions.concat(remaining);
    }

    remaining = otherClicked.concat(remaining);
    remaining = remaining.filter(q => !this.state.remainingQuestions.some(r => r.id === q.id));
    remaining = remaining.concat(this.state.remainingQuestions);

    if(clickedQuestions.some(q => q.id === 6))
    {
      remaining = remaining.filter(q => q.id !== 117);
    }

    this.setState((state) => ({
      question: question,
      clickedQuestions: state.clickedQuestions.concat(question),
      remainingQuestions: remaining,
      complete: answers.length === 0,
    }))
  }

  onBackClick = () => {
    let clickedQuestions = this.state.clickedQuestions;
    let backedQuestion = clickedQuestions.pop();

    let clickedQuestion = clickedQuestions[clickedQuestions.length - 1];

    let remainingQuestions = [];
    for (const question of clickedQuestions) {
      let questionRemaining = Data.Questions.filter(q => q.parent === question.id && q.isAnswer === false && !clickedQuestions.some(cq => cq.id === q.id));
      remainingQuestions = questionRemaining.concat(remainingQuestions);
    }

    if(backedQuestion.type === "checkbox" && backedQuestion.parent !== clickedQuestion.id)
    {
      remainingQuestions.unshift(backedQuestion);
    }

    this.setState((state, props) => ({
      question: clickedQuestion,
      clickedQuestions: clickedQuestions, 
      remainingQuestions: remainingQuestions,
      complete: false
    }))
  }

  getQuestionElement = (question) => {
    return (
      <Question 
        key={question.id} 
        question={this.state.question} 
        remaining={this.state.remainingQuestions} 
        currentQuestion={question} 
        clickedQuestions={this.state.clickedQuestions} 
        data={Data} 
        back={this.onBackClick.bind(this)} 
        index={this.state.clickedQuestions.length}
      />
    )
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    
    if(this.state.question === null || this.state.question === undefined)
    {
      window.$("form#wrapped").validate().form();
    }

    console.log("clickedQuestions:");
    console.log(this.state.clickedQuestions.map(q => q.id) + "");
    console.log("remainingQuestions:");
    console.log(this.state.remainingQuestions.map(q => q.id) + "");

    let key = Data.Questions.filter(q => q.clicked &&  this.state.clickedQuestions.some(cq => cq.id === q.parent))
    console.log("Key:")
    console.log(key.map(q => q.id) + "")

    let question = this.getQuestionElement(this.state.question);
    let isComplete = this.state.question.id == 145 || this.state.question.id == 147;
    console.log(this.state.question.id);

    if (isComplete)
    {
      this.props.history.push({
        pathname: '/answer',
        state: { key: key, clickedQuestions: this.state.clickedQuestions, data: Data.Questions }
      });
    }

    return (
      <div className="container-fluid full-height">
        <div className="row row-height">
          <Panel></Panel>

          <div className="col-lg-6 content-right" id="start">
            <div id="wizard_container">
              <div id="top-wizard">
                <div id="progressbar"></div>
              </div>
              <form id="wrapped" method="POST">
                <input id="website" name="website" type="text" defaultValue="" />
                <div id="middle-wizard">
                  {question}
                </div>
                <div id="bottom-wizard">
                  <button type="button" name="backward" className="backward" onClick={this.onBackClick} disabled={this.state.question.id === 1 ? "disabled" : null}>Назад</button>
                  <button type="button" name="forward" className="forward" onClick={this.onAnswerClick} disabled={isComplete ? "disabled" : null}>Напред</button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {this.state.complete === true ? <TermsAndConditions answerKey={key}></TermsAndConditions> : ""}
      </div>
    );
  }
}

Questions.propTypes = {
  // bla: PropTypes.string,
};

Questions.defaultProps = {
  // bla: 'test',
};

export default withRouter(Questions);
