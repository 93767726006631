import React, { PureComponent } from 'react';
//import { Test } from './Panel.styles';

class Panel extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
          <div className="col-lg-6 content-left">
            <div className="content-left-wrapper">
              {/* <a href="index.html" id="logo"><img src="img/logo.png" alt="" width="49" height="35" /></a> */}
              <div id="social">
                <ul>
                  <li><a href="https://web.facebook.com/Center.for.legislative/?epa=SEARCH_BOX"><i className="icon-facebook"></i></a></li>
                </ul>
              </div>
              <div>
                <figure><img src="img/panel-image.png" alt="" className="img-fluid w-50" /></figure>
                <h2>Въпросник</h2>
                <h2>"Правен балансьор"</h2>
                <p>Сравнете начините за решаване на Вашия правен казус. Отговорете на зададените въпроси и системата ще Ви информира за прогнозното времетраене и разноски на съдебните и извънсъдебни способи за решение.</p>

                
                <div>
                  <div className="container-fluid">
                    <div className="row" >
                      <div className="col text-center" style={{marginBottom: "30px", backgroundColor: "white"}}>
                        <img src="img/sponsor7.png" style={{maxWidth: "22%"}} alt="#" />
                        <img src="img/sponsor3.png" style={{maxWidth: "28%"}} alt="#" />
                      </div>
                    </div>
                  </div>
                  <p className="text-justify">Онлайн интерактивен въпросник за независима и безпристрастна оценка на възможностите за решаване на конкретни правни казуси и вариантите за извънсъдебното им решаване е разработен по проект „Правен балансьор - онлайн достъп до правосъдие“ по административен договор за предоставяне на безвъзмездна финансова помощ с рег. № BG05SFOP001-3.003-0079 от 18.02.2019 г. с бенефициент Сдружение "Център за законодателни оценки и законодателни инициативи", финансиран по Оперативна програма „Добро управление“, съфинансирана от Европейския съюз чрез Европейския социален фонд. Проектът е на стойност 83 215,30 лв., от които 70 733,00 лв. европейско финансиране и 12 482,30 лв. национално финансиране.</p>
                </div>

                <a href="http://balancer.legalsp.eu/" className="btn_1 rounded mobile_btn">Назад към Сайта</a>
                <a href="http://balancer.legalsp.eu/" className="btn_1 rounded">Назад към Сайта</a>
              </div>
              <div className="copy">© LegalSP</div>
            </div>
          </div>
    );
  }
}

Panel.propTypes = {
  // bla: PropTypes.string,
};

Panel.defaultProps = {
  // bla: 'test',
};

export default Panel;
