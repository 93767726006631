import React, { PureComponent } from 'react';
//import { Test } from './Slider.styles';

class Slider extends PureComponent { 
  constructor(props) {
    super(props);
    
    props.answer.clicked = true;
    props.answer.value = props.answer.value || props.answer.min;

    this.state = {
      hasError: false,
      value: props.answer.value
    };
  }

  componentDidMount = () => {
    let slector = "#" + this.props.answer.id;
    let $ = window.$;
    let self = this;
    
    $(slector).rangeslider({
      polyfill: false,
      onInit: function () {
        this.output = $(".budget_slider span").html(this.$element.val());
      },
      onSlide: function (
        position, value) {
        this.output.html(value);
        self.props.answer.value = value;
        $("[name='" + self.props.answer.parent + "Dec']").val(value);
      }
    });
  }

  onChange = (name) => {
    this.props.answer.clicked = true;
    let $ = window.$;

    let value = $("[name='" + name + "']").val();
    if(value == "")
    {
      value = 0
    }

    $("[name='" + this.props.answer.parent + "']").val(value).change();
    this.props.answer.value = value;
    console.log(value);
    this.setState({
      value: value
    })
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <div className="form-group">
        <div className="budget_slider">
          <input 
            type="number" 
            name={this.props.answer.parent + "Dec"} 
            className="form-control required mb-3" 
            placeholder={this.props.answer.question} 
            onChange={() => this.onChange(this.props.answer.parent + "Dec")} 
            defaultValue={this.state.value} />
          <input 
            id={this.props.answer.id}
            type="range" 
            name={this.props.answer.parent}
            min={0}
            max={this.props.answer.max} 
            step={1} 
            data-orientation="horizontal" 
            defaultValue={this.state.value}
            onChange={() => this.onChange(this.props.answer.parent)}
            />
          <span>100</span>
        </div>
      </div>
    );
  }
}

export default Slider;
