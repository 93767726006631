import React, { PureComponent } from 'react';

class Email extends PureComponent { 
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  onChange = () => {
    this.props.answer.clicked = true;
    
    let name = this.props.answer.parent + "";
    this.props.answer.value = window.$("[name='" + name + "']").val();
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return (
      <div className="form-group">
        <input 
          type="email" 
          name={this.props.answer.parent + ""} 
          className="form-control required" 
          placeholder="Вашият Email" 
          defaultValue={this.props.answer.value} 
          onChange={this.onChange}
        />
      </div>
    );
  }
}

Email.propTypes = {
  // bla: PropTypes.string,
};

Email.defaultProps = {
  // bla: 'test',
};

export default Email;
