import React, { Component } from 'react';
import './App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


import Main from './Main';
import AnswerPage from './AnswerPage';

export default class App extends Component {
  static displayName = App.name;

  render() {
      return (
        <Router>
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/answer" component={AnswerPage} />
          </Switch>
        </Router>
      );
  }
}
