import React, { PureComponent } from 'react';
import Answer from './Answer'
import Checkbox from './Checkbox'
import Date from './Date'
import Decimal from './Decimal'
import Dropdown from './Dropdown'
import Slider from './Slider'
import Email from './Email'
//import { Test } from './Question.styles';

class Question extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };

    this.wrapperRef = React.createRef();
  }

  onAnswerClick = (clickedAnswer) => {
    let connectedAnswers = [18, 19, 20];
    if(connectedAnswers.find(c => c === clickedAnswer.id))
    {
      window.$("[name='21']").prop("checked", false)
    }

    if(clickedAnswer.type === "checkbox" && clickedAnswer.id !== 21)
    {
      return;
    }

    let answers = this.props.data.Questions
      .filter(question => question.parent === this.props.currentQuestion.id)
      .filter(question => question.isAnswer === true)
      .filter(question => question.id !== clickedAnswer.id)

    for (const answer of answers) {
      answer.clicked = false;
      if(clickedAnswer.id === 21)
      {
        window.$("[name='" + answer.id + "']").prop("checked", false)
      }
    }
  }

  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    let answers = this.props.data.Questions
      .filter(question => question.parent === this.props.currentQuestion.id)
      .filter(question => question.isAnswer === true)
      .map(question => {
        switch(question.type)
        {
          case "date" : return <Date key={question.id} answer={question} />
          case "decimal" : return <Decimal key={question.id} answer={question} />
          case "slider" : return <Slider key={question.id} answer={question} action={this.props.action} />
          case "dropdown" : return <Dropdown key={question.id} clickedQuestions={this.props.clickedQuestions} data={this.props.data.Questions} answer={question} action={this.props.action}/>
          case "email" : return <Email key={question.id} answer={question} action={this.onAnswerClick} />
          case "checkbox": return <Checkbox key={question.id} answer={question} action={this.onAnswerClick} />
          default : return <Answer key={question.id} answer={question} action={this.onAnswerClick} />
        }
      });

    return (
      <div className="step">
        <h3 className="main_question"><strong>{this.props.index}/{this.props.clickedQuestions.length + this.props.remaining.length}</strong>{this.props.question.question}</h3>
        {answers}
      </div>
    );
  }
}

export default Question;