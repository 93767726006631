import React, { PureComponent } from 'react';
import { Data } from '../Questions/data'
//import { Test } from './TermsAndConditions.styles';

class TermsAndConditions extends PureComponent { 
  constructor(props) {
    super(props);

    let match = (key, selectedAnswers) => {
      let selectedKey = selectedAnswers.map(a => a.id);
      let keyArray = key.split(/[,.]+/).map(k => k.trim());

      let matchHits = 0;
  
      for (const key of keyArray) {
        let keyParts = key.split(/[(|)]/).filter(w => w != "").map(w => parseInt(w));
        let hasPart = selectedKey.some(sk => keyParts.some(kp => kp == sk));
  
        if(!hasPart)
        {
          return matchHits;
        }
        matchHits++;
      }

      return matchHits;
    }

    let sorted = Data.SmartAnswers.sort((a, b) => match(b.key, props.answerKey) - match(a.key, props.answerKey));
    let answer = sorted[0];
    
    this.state = {
      hasError: false,
      answer: answer,
    };
  }


  render () {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return (
      <div className="modal fade" id="terms-txt" tabIndex="-1" role="dialog" aria-labelledby="termsLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="termsLabel">Възможни решения на Вашия казус</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body text-justify row">
              <div style={{display: this.state.answer.mediationText != undefined ? "auto" : "none"}} className="col-sm">
                <h4 className="text-center">Медиация</h4>
                <div>{this.state.answer.mediationText}</div>
                <h5 style={{display: this.state.answer.mediationTime != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Време</h5>
                <div>{this.state.answer.mediationTime}</div>
                <h5 style={{display: this.state.answer.mediationCost != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Цена</h5>
                <div>{this.state.answer.mediationCost}</div>
              </div>
              <div style={{display: this.state.answer.courtText != undefined ? "auto" : "none"}} className="col-sm">
                <h4 className="text-center">Съд</h4>
                <div>{this.state.answer.courtText}</div>
                <h5 style={{display: this.state.answer.courtTime != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Време</h5>
                <div>{this.state.answer.courtTime}</div>
                <h5 style={{display: this.state.answer.courtCost != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Цена</h5>
                <div>{this.state.answer.courtCost}</div>
              </div>
              <div style={{display: this.state.answer.arbitrageText != undefined ? "auto" : "none"}} className="col-sm">
                <h4 className="text-center">Арбитраж</h4>
                <div>{this.state.answer.arbitrageText}</div>
                <h5 style={{display: this.state.answer.arbitrageTime != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Време</h5>
                <div >{this.state.answer.arbitrageTime}</div>
                <h5 style={{display: this.state.answer.arbitrageCost != undefined ? "auto" : "none", margin: "10px 0 0 0"}}>Цена</h5>
                <div>{this.state.answer.arbitrageCost}</div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_1" data-dismiss="modal">Затвори</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  // bla: PropTypes.string,
};

TermsAndConditions.defaultProps = {
  // bla: 'test',
};

export default TermsAndConditions;
